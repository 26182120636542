<template>
  <div class="flex flex-col md:flex-row justify-center items-center gap-[20px] md:gap-[40px] text-[20px] text-white">
    <NuxtLink v-for="menu in menuData" :key="menu.id" :to="menu.link">
      {{ menu.title }}
    </NuxtLink>
  </div>
</template>
<script setup>
const menuData = [
  {
    id: 1,
    title: 'Home',
    link: '/'
  },
  {
    id: 3,
    title: 'Roof Replacement',
    link: '/roofing-services/roof-replacements'
  },
  {
    id: 4,
    title: 'Financing',
    link: '/financing'
  },
  {
    id: 5,
    title: 'Metal Roofing',
    link: '/roof-types/metal/'
  },
  {
    id: 6,
    title: 'Asphalt Roofing',
    link: '/roof-types/asphalt/'
  },
  {
    id: 7,
    title: 'Contact Us',
    link: '/contact-us'
  }
]
</script>
